<template>
  <div class="bg">
    <div v-if="!(infoList && (infoList.length > 0))" class="no-data">
      <img class="nodata-img"
           :src="nodata"
           alt="">
      <div style="font-size: 0.3rem;color: #616161">暂无数据，请稍后查看~</div>
    </div>
    <div v-if="infoList && (infoList.length > 0)" style="height: 100%;background-color: #F7F8F9;">
      <div class="title">业绩统计</div>
      <van-pull-refresh v-model="isLoading"
                        @refresh="onRefresh"
                        style="padding-bottom: 50px;">
        <div>
          <div class="van-clearfix table">
            <!-- finished-text="没有更多了" -->
            <van-list :error.sync="error"
                      error-text="请求失败，点击重新加载"
                      v-model="loading"
                      :finished="finished"
                      @load="onLoad">
              <div class="van-clearfix">
                <div class="header">
                  <div class="flex justify_content_space_between">
                    <div class=""
                         style="width: 25%;margin: 5px 0">统计日期</div>
                    <div style="width: 15%;text-align: center;margin: 5px 0;">
                      <div>站点余额</div>
                      <div>(万元)</div>
                    </div>
                    <div style="width: 15%;text-align: center;margin: 5px 0">
                      <div>客户数</div>
                      <div></div>
                    </div>
                    <div style="width: 15%;text-align: center;margin: 5px 0">
                      <div>年日均</div>
                      <div>(万元)</div>
                    </div>
                    <div style="width: 15%;text-align: center;margin: 5px 0">
                      <div>月日均</div>
                      <div>(万元)</div>
                    </div>
                    <div style="width: 15%;text-align: right;margin: 5px 0">
                      <div>月均三年</div>
                      <div>占比%</div>
                    </div>
                  </div>
                </div>
                <div style="width: 100%;background: #eeeeee"></div>
                <performanceStatisticsCell v-for="(item,index) in infoList"
                                           :key="index"
                                           class="info"
                                           :info="item">
                </performanceStatisticsCell>
              </div>
            </van-list>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import performanceStatisticsCell from "./performanceStatisticsCell";
import { getPerformanceDetail } from "../../../utils/api";
import common from "../../../utils/common";
export default {
  components: {
    performanceStatisticsCell
  },
  props: ['stationNo'],
  name: "performanceInformationCell",
  data () {
    return {
      infoList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: '10',
      error: false,
      listHeight: null,
      nodata: require('@/assets/img/nodata/nodata.png'),
    }
  },
  mounted () {
    var h = document.documentElement.clientHeight || document.body.clientHeight;
    this.listHeight = h - (0.88 * 2 * (h / 7.5))
    this.onLoad();
  },
  methods: {
    toGetPerformanceDetail () {
      getPerformanceDetail({
        'stationNo': this.stationNo,
        'pageIndex': this.pageIndex.toString(),
        'pageSize': this.pageSize
      }).then(res => {
        // 加载状态结束
        this.loading = false;
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.finished = !res.data.pageModel.hasNextPage;
            if (this.pageIndex == 1) {
              this.infoList = res.data.pageModel.list;
            } else {
              this.infoList = this.infoList.concat(res.data.pageModel.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }
          } else {
            this.error = true;
          }
        } else {
          this.error = true;
        }
      }).catch(() => {
        this.error = true;
      })
    },
    onLoad () {
      this.toGetPerformanceDetail();
    },
    onRefresh () {
      this.infoList = [];
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.error = false;
      this.pageIndex = 1;
      this.onLoad();
    }

  }
}
</script>

<style lang="less" scoped>
.bg {
  margin: 0;
  width: 100%;
  height: 100%;
  .flex {
    display: flex;
  }
  .title{
    font-size: 0.36rem;
    padding: 0.24rem 0.3rem 0;
    font-weight: bold;
    color: #1e1e1e;
    background-color: #F7F8F9;
  }
  .no-data{
    width: 100vw;
    background-color: #F7F8F9;
    text-align: center;
    height: 100vh;
  }
  .nodata-img {
    width: 40%;
    margin-top: 2.5rem;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.88rem;
    .van-button {
      width: 100%;
    }
  }
  .search {
    margin: 0 0.2rem;
    background: #eeeeee;
    padding-top: 0.2rem;
  }
  .search-btn {
    color: #1989fa;
  }
  .table {
    margin: 0 0.2rem 0px 0.2rem;
    background: white;
    height: 100%;
    .header {
      margin-top: 0.2rem;
      padding-top: 15px;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      /*height: 1rem;*/
      /*line-height: 1.5rem;*/
      background: white;
      font-size: 12px;
      color: #999999;
    }
    .cell-title {
      text-align: left;
      /*width: 1.2rem;*/
      height: 0.5rem;
      line-height: 0.5rem;
      /*margin-top: 0.4rem;*/
      border-radius: 0.05rem;
      color: black;
    }
    .cell-bottom {
      text-align: center;
      /*width: 1.2rem;*/
      height: 0.5rem;
      line-height: 0.5rem;
      /*margin-top: 0.4rem;*/
      border-radius: 0.05rem;
      color: black;
    }
  }
}
</style>


