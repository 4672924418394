<template>
    <div class="width_full">
        <div  class="cell-bg ">
            <div class="flex justify_content_space_between width_full">
                <div class="first-label bold" >{{info.statisticDate.split(' ')[0]}}</div>
                <div class="second-label bold" >{{info.currBalance}}</div>
                <div class="third-label bold" >{{info.cardNum}}</div>
                <div  class="fourth-label bold" >{{info.annualDayAvg}}</div>
                <div class="fourth-label bold" >{{info.monthDayAvg}}</div>
                <div class="last-label bold" >{{info.threeYearDeposit}}</div>
            </div>
        </div>
      <!--  <div  class="line">
        </div>-->
    </div>
</template>

<script>
    export default {
        name: "performanceStatisticsCell",
        props:['info'],
        filters: {

        },
        data(){
            return {
            }
        },
        mounted() {
        },
        methods:{

        }
    }
</script>

<style lang="less" scoped>
    .bg{
        .cell-bg{
            margin: 0 0.3rem;
            height: 48px;
            line-height: 48px;
        }
        .name-label{
            font-size: 12px;
            text-align: center;
            width: 15%
        }
        .first-label{
            text-align: left;
            font-size: 12px;
            width: 25%;
            color: #222222;
        }
        .second-label{
            text-align: center;
            font-size: 12px;
            width: 15%;
            color: #3F7C53;
        }
        .third-label{
            text-align: center;
            font-size: 12px;
            width: 15%;
            color: #222222;
        }
        .fourth-label{
            text-align: center;
            font-size: 12px;
            width: 15%;
            color: #828282;
        }
        .last-label{
            text-align: right;
            font-size: 12px;
            color: #828282;
            width: 15%
        }

        .line{
            height: 0.5px;
            background-color: #eeeeee;
            margin-top: -1px;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
        }

        span{
            word-break:normal;
            width:auto;
            display:block;
            white-space:pre-wrap;
            word-wrap : break-word ;
            overflow:scroll ;
        }
    }

</style>



